import React from 'react';

import Elements from './Elements'

import { useString } from "providers/LocalisationProvider";
import { ProfileIcons } from 'fixit-shared/Consts';

export const ProfilePicker = ({profile, renderFixitButton, renderAchievementButton, onEditCallback, onDeleteCallback, onDownloadCallback}) => {
    const textKey = `${profile.raceIcons.toLowerCase()}_${profile.genderIcons.toLowerCase()}`
    const iconKey = `${profile.raceIcons}${profile.genderIcons}`
    return (
        <div className='flexbox profile-picker-item'>
            <div className='profile-summary rounded'>
                <img className='profile-summary-icon' src={ProfileIcons[iconKey]} alt={useString(`${textKey}_option`)}/>
                <div className='profile-summary-name'>{profile.displayName} </div>
            </div>
            {renderFixitButton()}
            {renderAchievementButton()}
            {Elements.renderLink('secondary-button rounded profile-picker-button', `profile/${profile.id}`, 
            {profile}, 
            useString('profile_incidents'),
            profile.incidents.length > 0)}
            {/* <button className='secondary-button rounded profile-picker-button' onClick={onEditCallback}>{useString('edit')}</button> */}
            {Elements.renderImageButton(onDownloadCallback, '/icons/download.svg', 'download-button rounded', useString('download'))}
            {Elements.renderImageButton(onDeleteCallback, '/icons/delete.svg', 'delete-button rounded', useString('delete'))}
        </div>
    )
}
