class UserKeys {
    constructor(){
        this.tableName = "user";
        this.id = 'u';
        this.displayName = 'n';
        this.readOnly = 'ro';
        this.paidProfiles = 'pp';
        this.freeProfiles = 'fp';
        this.subscription = 's';
        this.email = 'e';
        this.address = 'a';

        this.decompress = this.decompress.bind(this);
    }

    decompress(doc) {
        const data = doc.data();
        if(!data) {
            return {id: doc.id};
        }
        const readOnlyData = data ? data[this.readOnly] : undefined;

        if(!readOnlyData)
        {
            console.error(`The profile returned (${id}) is invalid`)
            return {id: doc.id};
        }

        return {
            id: doc.id,
            displayName: data[this.displayName],
            paidProfiles: readOnlyData[this.paidProfiles],
            freeProfiles: readOnlyData[this.freeProfiles],
            subscription: readOnlyData[this.subscription],
            email: data[this.email],
            address: data[this.address],
        }
    }
}

class ProfileKeys {
    constructor(){
        this.tableName = "profile";
        this.displayName = 'ds';
        this.genderIcons = 'g';
        this.raceIcons = 'r';
        this.owner = 'o';
        this.sessionRunners = 'rs';
        this.incidents = 'i';
        this.certificatesOfAchievement = 'coa';
        this.consentObtained = 'c';
        this.version = 'v';
        this.compress = this.compress.bind(this);
        this.decompress = this.decompress.bind(this);
    }

    compress(data, owner) {
        return {
            [this.version]: data.version,
            [this.displayName]: data.displayName,
            [this.genderIcons]: data.genderIcons,
            [this.raceIcons]: data.raceIcons,
            [this.incidents]: data.incidents,
            [this.certificatesOfAchievement]: data.certificatesOfAchievement,
            [this.consentObtained]: data.consentObtained,
            [this.owner]: owner
        }
    }

    decompress(doc) {
        const data = doc.data();
        return {
            id: doc.id,
            version: data[this.version],
            displayName: data[this.displayName],
            genderIcons: data[this.genderIcons],
            raceIcons: data[this.raceIcons],
            incidents: data[this.incidents],
            certificatesOfAchievement: data[this.certificatesOfAchievement],
            consentObtained: data[this.consentObtained],
            owner: data[this.owner]
        }
    }
}

class ReportKeys {
    constructor(){
        this.decompress = this.decompress.bind(this);
    }

    decompress(doc) {
        const data = doc.data();
        return {
            id: doc.id,
            date: new Date(parseInt(doc.id)),
            questions: data.questions,
            notes: data.notes || "",
        }
    }
}

class ReportTypes {
    constructor(){
        this.Incident = "incident";
        this.CertificateOfAchievement = "coa";
    }

    profileKey(type){
        const profileKeys = new ProfileKeys();
        if (type == this.Incident) return profileKeys.incidents;
        if (type == this.CertificateOfAchievement) return profileKeys.certificatesOfAchievement;
        return null;
    }
}

module.exports = {
    UserKeys: new UserKeys(),
    ProfileKeys: new ProfileKeys(),
    ReportKeys: new ReportKeys(),
    ReportTypes: new ReportTypes(),
}