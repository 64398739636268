import firebase from "firebase/app";
import { ProfileKeys, ReportTypes } from 'fixit-shared/FirestoreKeys';
import { saveReport } from './reports';

const CREATE_PROFILE_FUNCTION = 'createProfile';
const DELETE_PROFILE_FUNCTION = 'deleteProfile';
const DELETE_ALL_PROFILES_FUNCTION = 'deleteAllOwnedProfiles';

export const getProfilesRef = (uid) => {
  if(!uid) return undefined;
  return firebase.firestore().collection(ProfileKeys.tableName)
  .where(ProfileKeys.sessionRunners, "array-contains", uid);
};

const _reportAddSubjectProfileTelemetry = (userProfile,  subjectProfile, remainingProfiles) => {
  const telemetryProps = {
      profile_uuid: userProfile.id,
      chosen_profile_icon: [subjectProfile.raceIcons, subjectProfile.genderIcons],
      succeeded: subjectProfile.id !== undefined,
      max_profiles: userProfile.paidProfiles + userProfile.freeProfiles,
      profiles_remaining: remainingProfiles,
  };
  firebase.analytics().logEvent('account.created_profile', telemetryProps);
}

export const saveProfile = async (cloudFunctions, userProfile, profile, incidentCollection) => {
  const profileData = ProfileKeys.compress(profile, userProfile.id);
  profileData[ProfileKeys.sessionRunners] = [userProfile.id];

  const createProfile = cloudFunctions.httpsCallable(CREATE_PROFILE_FUNCTION);
  try {
    const result = await createProfile({ profileData });
    const data = result.data;
    profile.id = data.id;
    if (incidentCollection) {
      incidentCollection.forEach(incident => { incident.profileId = profile.id; });
      const incidentSavePromises = incidentCollection.map(incident => saveReport(ReportTypes.Incidents, incident));
      Promise.resolve(Promise.all(incidentSavePromises));
    }
    _reportAddSubjectProfileTelemetry(userProfile, profile, data.remainingProfiles);
    return data;
  } catch (error) {
    _reportAddSubjectProfileTelemetry(userProfile, profile, error.remainingProfiles);
    return { error };
  }
};

export const deleteProfile = async (cloudFunctions, profileId, user) => {
  const deleteProfile = cloudFunctions.httpsCallable(DELETE_PROFILE_FUNCTION);
  const result = await deleteProfile({profileId});
  const telemetryProps = {
    profile_uuid: profileId,
    max_profiles: user.paidProfiles + user.freeProfiles,
    profiles_remaining: result.remainingProfiles,
  };
  firebase.analytics().logEvent('account.deleted_profile', telemetryProps);
};

export const deleteAllOwnedProfiles = async(cloudFunctions) => {
  const deleteProfiles = cloudFunctions.httpsCallable(DELETE_ALL_PROFILES_FUNCTION);
  const result = await deleteProfiles();
  return result;
}

export const updateSubjectProfile = (profile, user) => {
  const compressedData = ProfileKeys.compress(profile, user.id);
  return firebase.firestore().collection(ProfileKeys.tableName).doc(profile.id).update(compressedData);
}